.popup-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000F0;
    width: 100vw;
    height: 100vh;

    .regions {
        margin-top: 25%;
        width: 800px;
        margin-left: calc(50% - 400px);
        text-align: center;

        .close-button {
            background: $background;
            margin-top: 20px;
            padding: 5px;
            width: 50%;
            color: $mainfontcolor;
            border: none;
            cursor: pointer;
            display: block;
            margin-left: 25%;
        }

        h2 {
            color: $mainfontcolor;
        }

        .region {
            display: inline-block;
            margin: 20px;
            cursor: pointer;

            img {
                padding: 11px;
                height: 150px;
            }
        }

        .scot {
            height: 150px;

            img {
                padding: 0;
                height: 175px;
            }
        }

        .retailer {
            display: inline-block;
            background: white;
            margin: 10px;
            padding: 5px;
            border-radius: 10px;
            cursor: pointer;

            img {
                height: 80px;
            }
        }
    }

    @media(max-width: 600px){
        .regions {
            width: 400px;
            margin-left: calc(50% - 200px);

            .retailer {
                img {
                    height: 50px;
                }
            }
        }
    }

    @media(max-width: 400px){
        .regions {
            width: 250px;
            margin-left: calc(50% - 125px);

            .retailer {
                img {
                    height: 40px;
                }
            }
        }
    }
}