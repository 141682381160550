.language-page {
    padding: 50px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    h2 {
        margin-top: 50px;
    }

    .translate-bloc {
        border-top: 1px white dashed;
        border-bottom: 1px white dashed;

    .translation {
            display: inline-block;
            margin: 15px;
            margin-left: 0;
            border-right: 1px solid white;
        p {
            display: inline-block;
            margin-right: 15px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .end {
        border: none;
    }
}

    .link {
        padding: 10px;
        border: white 1px solid;
        border-radius: 20px;
        width: 100px;
        margin-left: auto;
        margin-right: auto;

        a {
            color: white;
            text-decoration: none;
            font-weight: 500;
            font-variant-caps: small-caps;
        }    
    }    

    @media(max-width: 750px){
        padding: 20px;
        width: calc(100% - 40px);
    }  
}