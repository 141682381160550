.buy-modal{
    position: fixed;
    padding-left: 50px;
    top: calc(50% - 200px);
    right: 0;
    background-image : linear-gradient(to left, 
                    rgba(20,20,50, 1), 
                    rgba(20,20,50, 0) 100%);
    width: 150px;
    height: 400px;

    img {
        height: 50%;
        width: auto;
    }
}

@media(max-width: 600px){
    .buy-modal{
        padding-left: 0;
        bottom: 0;
        top: auto;
        left: 0;
        width: calc(100vw - 0px);
        height: 150px;
        background-image : linear-gradient(to top, 
                    rgba(20,20,50, 1), 
                    rgba(20,20,50, 0) 100%);

        img, h2, h3 {
            display: inline-block;
            vertical-align: middle;
            padding: 10px;
        }

        h2, h3 {
            font-size: 16px;
        }

        img {
            height: 80%;
        }
    }
}

@media(max-width: 400px){
    .buy-modal{
        height: 120px;

        img, h2, h3 {
            display: inline-block;
            vertical-align: middle;
            padding: 5px;
        }

        h2, h3 {
            font-size: 12px;
        }

        img {
            height: 80%;
        }
    }
}