body {
    background-color: $background;
#root {
    position: absolute;
    top: 0;
    left: 0;

    .App {
        margin: 0;
        min-width: calc(100vw - 20px);
        overflow-x: none;
        text-align: center;
        color: $mainfontcolor;

        h1, h2, h3 {
            font-family: $mainfont;
            font-variant: small-caps;
        }
    }
}
}