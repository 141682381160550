.App {
    .pdf {
        margin-left: auto;
        margin-right: auto;
        max-width: 750px;
        padding: 50px;
        text-align: justify;

        p, b, h2, h3 {
            font-family: Arial, Helvetica, sans-serif !important;
        }

        a {
            font-size: 16px;
        }
    }
}