.goodreads{
    background-color: white;
    max-width: 100%;
    padding: 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #382110;

    .gr_title {
        display: block;
        padding-bottom: 10px;
    }

    .gr_container {
        a {
            color: #00635d;
            text-decoration: none;
        }
        display: block;
        font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;

        img {
            border: 0;
        }

    .gr_book_container {
        padding: 5px;
        border-bottom: 1px solid #d7d7d7;
        font-size: 14px;
        display: inline-block;
        max-width: 220px;
        height: 100px;
        vertical-align: top;
    
  
    .gr_book_image {
        float: left; 
        padding-right: 20px;
    }

    .gr_review_stats {
        vertical-align: top;
        text-align: left;
    }
    }
    }

    #goodreads-widget {
        font-family: georgia, serif;
        padding: 18px 0;
        max-width:100%;
      }
      #goodreads-widget h1 {
        font-weight:normal;
        font-size: 16px;
        border-bottom: 1px solid #BBB596;
        margin-bottom: 0;
      }
      #goodreads-widget a {
        text-decoration: none;
        color:#660;
      }
      iframe{
        background-color: #fff;
        max-width:1000px;
        width: calc(100% - 350px);

        .gr_reviews_container{
            padding: 10px;
        }
      }
      #goodreads-widget a:hover { text-decoration: underline; }
      #goodreads-widget a:active {
        color:#660;
      }
      #gr_footer {
        width: 100%;
        max-width:1000px;
        border-top: 1px solid #BBB596;
        text-align: right;
      }
      #goodreads-widget .gr_branding{
        color: #382110;
        font-size: 11px;
        text-decoration: none;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      
@media(max-width: 600px){
    iframe{
        width: 100%;
      }
}
}
