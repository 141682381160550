
.header-menu {
    width: calc(100% - 40px);
    margin: 20px;
    display: grid;
    grid-template-columns: 160px auto 160px;
    grid-template-rows: 75px 25px;

    .menu {
        display: none;
    }

    h1 {
        grid-column: 2;
    }

    .menu-items{
        grid-row: 2;
        grid-column: 2;
        text-align: center;

        a {
            font-family: $mainfont;
            color: $mainfontcolor;
            display: inline-block;
            margin-left: 20px;
            text-decoration: none;
        }

        .active {
            color: #888;
        }
    }

    .social {
        margin-top: 5px;
        grid-column: 3;

        a {
            float: right;
            margin-left: 5px;
            width: 15px;
            height: 15px;

            img {
                width: 15px;
            }

            p {
                margin: 0;
                color: #ddddff;
                background-color: #4444ff;
                border-radius: 2px;
                font-weight: 900;
                font-size: 13px;
            }
        }

        .instagram {
            img {
                margin-left: -5px;
                height:15px;
                width:30px;
            }
        }
    }

    .header-logo {
        grid-area: 1 / 1 / 3 / 1;
        width: 60px;
        grid-column: 1;
    }
}

@media(max-width: 750px){
    .header-menu {
        grid-template-columns: 160px auto 160px;

        .menu-items{
            margin-left: auto;
            a {
                font-size: 16px;
            }
        }

        .header-logo {
            width: 50px;
        }
    }
}

@media(max-width: 820px){
    .header-menu {
        grid-template-columns: 160px auto 160px;

        .menu-items{
            text-align: center;

            a {
                font-size: 12px;
            }
        }

        .header-logo {
            width: 40px;
        }
    }
}

@media(max-width: 655px){
    .header-menu {
        grid-template-rows: 30px 20px;
        grid-template-columns: 50px auto 160px;
        height: 50px;

        h1 {
            margin-top: 5px;
            font-size: 16px;
            grid-row: 1;
            width: auto;
        }

        .menu {
            display: block;
            -webkit-filter: invert(100%);
            filter: invert(100%);
            right: 10px;
            position: absolute;
            cursor: pointer;
            border: none;
            background: transparent;

            img {
                height: 30px;
            }
        }

        .menu-items {
            display: none;
            width: 180px;
            padding: 10px;
            z-index: 1;
            position: absolute;
            right: 10px;
            text-align: right;
            top: 60px;
            background-color: black;
            border-radius: 10px;
            
            a {
                display: block;
                font-size: 16px;
                margin: 0 10px 5px 10px;
            }
        }

        .show-menu {
            display: block;
        }

        .social {
            grid-row: 2;
            grid-column: 3;

            a {
                display: inline-block;
                float: none;
                vertical-align: top;
            }
        }

        .header-logo {
            width: 40px;
            margin-top: -5px;
        }
    }
}

@media(max-width: 450px){
    .header-menu {
        grid-template-rows: 30px 20px;
        grid-template-columns: 50px auto 50px;
        height: 50px;

        h1 {
            margin-top: 5px;
            font-size: 16px;
            grid-row: 1;
            width: auto;
        }

        .menu {
            grid-column: 4;
            display: block;
            -webkit-filter: invert(100%);
            filter: invert(100%);
            right: 10px;
            position: absolute;
            cursor: pointer;
            border: none;
            background: transparent;

            img {
                height: 30px;
            }
        }

        .menu-items {
            display: none;
            width: 180px;
            padding: 10px;
            z-index: 1;
            position: absolute;
            right: 10px;
            text-align: right;
            top: 60px;
            background-color: black;
            border-radius: 10px;
            
            a {
                display: block;
                font-size: 16px;
                margin: 0 10px 5px 10px;
            }
        }

        .show-menu {
            display: block;
        }

        .social {
            grid-row: 2;
            grid-column: 2;

            a {
                display: inline-block;
                float: none;
                vertical-align: top;
            }
        }

        .header-logo {
            width: 40px;
            margin-top: -5px;
        }
    }
}