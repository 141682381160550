.main-page{
    button {
        background: none;
        border: none;
    }

    .series-title {
        img {
            width: 100%;
            max-height: 150px;
        }

        h2 {
            position: absolute;
            width: 340px;
            top: 180px;
            left: calc(50% - 150px);
        }
    }

    .buy-direct {
        background-color: #222266;
        padding: 20px;
        
        a {
            font-family: $mainfont;
            color: white;
            text-decoration: none;
        }

        .arrow {
            height: 15px;
            filter: invert(100%)
        }

        .arrow-one {
            margin-right: 15px;
            transform: rotate(90deg);
        }

        .arrow-two {
            margin-left: 15px;
            transform: rotate(-90deg);
        }
    }

    .books {
        padding-right: 150px;

        .book-item {
            display: inline-block;
            margin: 20px;
            color: $mainfontcolor;
            width: 400px;
            vertical-align: top;

            .main-book-content {
                display: inline-block;
                width: 250px;

                h3 {
                    font-weight: 100;
                    font-size: 18px;
                    font-family: $mainfont;
                }
    
                img {
                    height: 250px;
                }

                p {
                    text-align: justify;
                }
            }

            .book-links{
                display: inline-block;
                vertical-align: top;
                padding: 10px;
                padding-left: 20px;

                .book-link {
                    margin: 0;
                    text-align: left;
                    padding-bottom: 5px;

                    a {
                        margin: 0;
                        padding: 0;
                        color: lightblue;
                        text-decoration: none;
                    }
                }
            }
        }

        .map-container {
            display: inline-block;
        }
    }

    .map-section {
        overflow: auto;

        img {
            width: 60%;
            max-width: 500px;
        }
    }
    
    .map-selected {
        position: absolute;
        background: #00000080;
        top: 0;
        left: 0;
        overflow: auto;
        width: 100vw;
        height: calc(100vh);

        img {
            max-width: none;
            height: calc(100% - 20px);
            width: auto;
        }
    }


    @media(max-width: 900px){
        .map-selected {
            img {
                height: 1000px;
            }
        }
    }

    @media(max-width: 570px){
        .series-title {
            img {
                width: 100%;
                max-height: 100px;
            }
    
            h2 {
                font-size: 20px;
                position: absolute;
                width: 100%;
                top: 110px;
                left: 0;
                text-align: center;
            }
        }

        .map-section {
            img {
                width: 80%;
            }
        }

        .map-selected {
            img {
                width: auto;
            }
        }
    }
}

.popup-active {
    height: 100vh;
    overflow: hidden;
}

@media(max-width: 900px){
    .main-page {
        padding-bottom: 150px;
        
        .books {
            padding-right: 0;
            h2 {
                padding-left: 100px;
                width: calc(100% - 100px);
            }
        }
    }
}

@media(max-width: 480px){
    .main-page {
        .buy-direct {
            padding: 10px;

            a {
                display: inline-block;
                width: calc(100vw - 120px);
            }

            .arrow {
                padding-bottom: 18px;
            }
        }

        .books {
            .book-item {
                display: block;
                width: 250px;
                margin-left: calc(50vw - 125px);
    
                .book-links{
                    display: block;
                    width: 250px;
                    padding: 0;
    
                    .book-link {
                        margin: 0;
                        text-align: left;
                        padding-bottom: 5px;
    
                        a {
                            margin: 0;
                            padding: 0;
                            color: lightblue;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}