$mainheight: 400px;
$mainsubheight: 349px;
$mainsubmargin: 25px;

$midiheight: 260px;
$midisubheight: 230px;
$midisubmargin: 15px;

$miniheight: 150px;
$minisubheight: 130px;
$minisubmargin: 10px;

.swiper {
    height: 450px;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    
    touch-action: none;

    #almg {
        width: 270px;
    }

    .directions {
        svg {
            margin: $midisubmargin;
        }
    }

    .left-click {
        z-index: 2;
        height: 400px;
        position: absolute;
        width: 300px;
    }

    .swiper-container{
        width: 800px;
        touch-action: none;
    
        div {
            display: none;
            vertical-align: top;
            touch-action: none;

            img {
                height: $mainheight;
                touch-action: none;
            }
        }
    }

    .first {
        div:nth-child(1) {
            display: inline-block;
            perspective: 1000px;
        }
        div:nth-child(2) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
                transform: rotateY( 60deg );
            }
        }
    }

    .second {
        div:nth-child(1), div:nth-child(3) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(2) {
            display: inline-block;
        }
        div:nth-child(1) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(3) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }

    .third {
        div:nth-child(2), div:nth-child(4) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(3) {
            display: inline-block;
        }
        div:nth-child(2) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(4) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }

    .forth {
        div:nth-child(3), div:nth-child(5) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(4) {
            display: inline-block;
        }
        div:nth-child(3) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(5) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }

    .fifth {
        div:nth-child(4), div:nth-child(6) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(5) {
            display: inline-block;
        }
        div:nth-child(4) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(6) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }

    .sixth {
        div:nth-child(5), div:nth-child(7) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(6) {
            display: inline-block;
        }
        div:nth-child(5) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(7) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }

    .seventh {
        div:nth-child(6), div:nth-child(8) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(7) {
            display: inline-block;
        }
        div:nth-child(6) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(8) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }

    .eighth {
        div:nth-child(7), div:nth-child(9) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(8) {
            display: inline-block;
        }
        div:nth-child(7) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(9) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }
    .ninth {
        div:nth-child(8), div:nth-child(10) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(9) {
            display: inline-block;
        }
        div:nth-child(8) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(10) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }
    .tenth {
        div:nth-child(9), div:nth-child(11) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(10) {
            display: inline-block;
        }
        div:nth-child(9) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(11) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }
    .eleventh {
        div:nth-child(10), div:nth-child(12) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
            }
        }
        div:nth-child(11) {
            display: inline-block;
        }
        div:nth-child(10) {
            img {
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(12) {
            img {
                transform: rotateY( 60deg );
            }
        }
    }
    .twelth {
        div:nth-child(11) {
            display: inline-block;
            perspective: 1000px;
            transition: transform 1s;
            transform-style: preserve-3d;
            img {
                margin-top: $mainsubmargin;
                height: $mainsubheight;
                transform: rotateY( -60deg );
            }
        }
        div:nth-child(12) {
            display: inline-block;
        }
    }
}

@media(max-width: 800px){
    .swiper {
        width: 530px;
        height: 350px;

        .swiper-container
        {
            width: 530px;

            div {
                img {
                    height: $midiheight;
                }
            }
        }

        .first {
        div:nth-child(2) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }

    .second {
        div:nth-child(1), div:nth-child(3) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }

    .third {
        div:nth-child(2), div:nth-child(4) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }

    .forth {
        div:nth-child(3), div:nth-child(5) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }

    .fifth {
        div:nth-child(4), div:nth-child(6) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }

    .sixth {
        div:nth-child(5), div:nth-child(7) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }

    .seventh {
        div:nth-child(6), div:nth-child(8) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }
    .eighth {
        div:nth-child(7), div:nth-child(9) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }
    .ninth {
        div:nth-child(8), div:nth-child(10) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }
    .tenth {
        div:nth-child(9) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }
    .eleventh {
        div:nth-child(10) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }
    .twelth {
        div:nth-child(11) {
            img {
                margin-top: $midisubmargin;
                height: $midisubheight;
            }
        }
    }
    }
}

@media(max-width: 450px){
    .swiper {
        width: 300px;
        height: 200px;

        .swiper-container
        {
            width: 300px;

            div {
                img {
                    height: $miniheight;
                }
            }
        }

        .first {
        div:nth-child(2) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }

    .second {
        div:nth-child(1), div:nth-child(3) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }

    .third {
        div:nth-child(2), div:nth-child(4) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }

    .forth {
        div:nth-child(3), div:nth-child(5) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }

    .fifth {
        div:nth-child(4), div:nth-child(6) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }

    .sixth {
        div:nth-child(5), div:nth-child(7) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }

    .seventh {
        div:nth-child(6), div:nth-child(8) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }
    .eighth {
        div:nth-child(7), div:nth-child(9) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }
    .ninth {
        div:nth-child(8), div:nth-child(10) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }
    .tenth {
        div:nth-child(9) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }
    .eleventh {
        div:nth-child(10) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }
    .twelth {
        div:nth-child(11) {
            img {
                margin-top: $minisubmargin;
                height: $minisubheight;
            }
        }
    }
    }
}